{/* */ }

import React from "react"
import "./screen.css";
const FScreen = () => {
  return (<>
    <div id='fullpage'>
      <div className="item">1</div>
      <div className="item">2</div>
      <div className="item">3</div>
      <div className="item">4</div>
      <div className="item">5</div>
      <div className="item">6</div>
    </div>
  </>)
}

export default FScreen